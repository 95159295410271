import { useEffect, useState } from 'react';

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '';

const screens: Record<Breakpoint, number> = {
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '': 0, // Default case for when no breakpoint matches
};

const useBreakpoint = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>('');
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the size

    const updateBreakpoint = () => {
      const width = windowSize.width;

      if (width === undefined) return;

      if (width < screens.sm) {
        setBreakpoint('');
      } else if (width < screens.md) {
        setBreakpoint('sm');
      } else if (width < screens.lg) {
        setBreakpoint('md');
      } else if (width < screens.xl) {
        setBreakpoint('lg');
      } else if (width < screens['2xl']) {
        setBreakpoint('xl');
      } else {
        setBreakpoint('2xl');
      }
    };

    updateBreakpoint();

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);

  return breakpoint;
};

export default useBreakpoint;
