import OfferTile from './OfferTile';
import { useStateContext } from '../../../utils/stateContext';
import SlidingTiles from './SlidingTiles';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import useBreakpoint from '../../../hooks/useBreakpoint';

const classNames = {
  titlesContainer: 'flex gap-1 w-full h-full justify-center items-center relative',
  tile: 'flex justify-center items-center w-full h-full',
};

const OfferTiles = () => {
  const {
    offers: { offers, selectedOffer, setSelectedOffer, fetchOffers, loading },
  } = useStateContext();

  useEffect(() => {
    fetchOffers();
  }, []);

  const breakPoint = useBreakpoint();

  if (loading) return <CircularProgress />;

  const scale = {
    sm: 0.45,
    md: 0.55,
    lg: 0.65,
    xl: 0.75,
  };

  const overlap = {
    sm: 0.35,
    md: 0.5,
    lg: 0.75,
    xl: 0.85,
  };

  const width = {
    sm: 250,
    md: 280,
    lg: 280,
    xl: 340,
  };

  return (
    <>
      <SlidingTiles
        scale={scale[breakPoint]}
        overlap={overlap[breakPoint]}
        width={width[breakPoint]}
      >
        {offers?.map((offer) => (
          <div key={offer.id} className={classNames.tile} onClick={() => setSelectedOffer(offer)}>
            <OfferTile offer={offer} selected={selectedOffer?.id === offer.id} />
          </div>
        ))}
      </SlidingTiles>
    </>
  );
};

export default OfferTiles;
