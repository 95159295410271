import ArvalisLogo from '../../icons/ArvalisLogo';
import PredimaOfferLogo from './PredimaOfferLogo';
import OfferProductsFexx from '../../../images/offer_products_flexx.png';
import OfferProductsLeaf from '../../../images/offer_products_leaf.png';
import OfferProductsOne from '../../../images/offer_products_one.png';
import { useTranslation } from 'react-i18next';

const classNames = {
  offerTile:
    'transition flex flex-col h-full relative items-center justify-evenly p-4 rounded-xl border border-primary-400 cursor-pointer',
  selected: 'bg-[#F6FFEC]',
  unselected: 'bg-background-1',
  productTile: 'w-[70px] h-[70px] bg-gray-300 shadow-custom-modal',
  row: 'flex gap-8',
  col: 'flex flex-col items-center',
  price: 'text-4xl text-primary-500 font-bold',
  priceUnit: 'ml-1 text-xl font-normal',
  productsContainer: 'flex flex-col items-center py-1 justify-center border-b border-t w-3/4 ',
  fixedPriceTag: 'text-sm text-1 font-light',
  Tlabel:
    'absolute top-[-24px] bg-[#F6FFEC] border border-primary-400 px-4 rounded-[30px] text-white',
  bottomPriceTag: 'absolute bottom-[-15px] py-1 bg-primary-500 px-5 rounded-[50px] text-white',
  bottomText: 'text-text-1 text-sm font-light text-center',
};

type OfferName = 'Leaf' | 'One' | 'Flexx';
interface Offer {
  id: number;
  distributor_id: number;
  name: string;
  price: number;
}
interface OfferTileProps {
  selected?: Boolean;
  offer?: Offer;
}
const OfferTile = ({ selected = false, offer }: OfferTileProps) => {
  const { t } = useTranslation();

  const locale = JSON.parse(localStorage.getItem('locale')) ?? 'fr';
  const guaranteeToPriceMapTemp = {
    fr: { 1: 93, 2: 93, 3: 70 },
  };

  const guarantee = guaranteeToPriceMapTemp?.[locale]?.[offer.id];

  return (
    <div
      className={`${classNames.offerTile} ${selected ? classNames.selected : classNames.unselected}`}
    >
      <span className={classNames.Tlabel}>
        <div className={`${classNames.row} items-center relative`}>
          <PredimaOfferLogo offerName={offer.name as OfferName} />
        </div>
      </span>
      <span className={'text-center pt-5 font-light'}>
        {t('OFFERS.TILES.LINE1')}
        <ArvalisLogo className={'flex place-self-center w-20'} />
      </span>
      <div className={classNames.row}>
        <div className={classNames.col}>
          <div className={classNames.productsContainer}>
            {offer.name === 'Predima Flexx' && (
              <>
                <div className={classNames.fixedPriceTag}> {t('OFFERS.TILES.FLEX')}</div>
                <img className={'py-2'} src={OfferProductsFexx} alt={t('OFFERS.TILES.FLEX')} />
              </>
            )}
            {offer.name === 'Predima Leaf' && (
              <>
                <div className={classNames.fixedPriceTag}>{t('OFFERS.TILES.LEAF')}</div>
                <img className={'py-2'} src={OfferProductsLeaf} alt={t('OFFERS.TILES.LEAF')} />
              </>
            )}
            {offer.name === 'Predima One' && (
              <>
                <img className={'w-[110px] py-2'} src={OfferProductsOne} alt="Kardix" />
              </>
            )}
          </div>
          <div className={`${classNames.col} pt-4`}>
            <span className={classNames.bottomText}>
              {t('OFFERS.TILES.OUTCOME_GUARANTEE', { price: guarantee })}
            </span>
            <span className={classNames.bottomText}>{t('OFFERS.TILES.REFUNDED')}</span>
          </div>
        </div>
      </div>
      <span className={classNames.bottomPriceTag}>
        {t('OFFERS.TILES.UNIQUE_PRICE_TAG', { price: offer?.price?.toFixed(2) })}
      </span>
    </div>
  );
};

export default OfferTile;
