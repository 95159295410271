import Card from '../../../Layout/Card';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../utils/stateContext';
import HomeIcon from '../../../icons/HomeIcon';

const DeliveryDetails = () => {
  const { t } = useTranslation();
  const {
    initialState: { sharedState },
  } = useStateContext();

  const NOT_AVALABLE = t('GENERIC.NOT_AVALABLE');

  return (
    <Card
      title={`${t('GENERIC.DELIVERY')} ${t('GENERIC.DETAILS')}`}
      icon={<HomeIcon className="w-5 h-5 text-white" />}
    >
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.CONTACT')}</span>
        <span>{sharedState?.order?.order_contact_name || NOT_AVALABLE}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.MOBILE_NO')}</span>
        <span>{sharedState?.order?.order_mobile_no || NOT_AVALABLE}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.DEL_ADDRESS')}</span>
        <span>{sharedState?.order?.order_del_address || NOT_AVALABLE}</span>
      </div>
    </Card>
  );
};

export default DeliveryDetails;
