import Card from '../../../Layout/Card';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../utils/stateContext';
import UserIcon from '../../../icons/UserIcon';

const PersonalDetails = () => {
  const { t } = useTranslation();
  const {
    initialState: { sharedState },
  } = useStateContext();

  const NOT_AVALABLE = t('GENERIC.NOT_AVALABLE');
  const YES = t('GENERIC.YES');
  const NO = t('GENERIC.NO');

  return (
    <Card title={t('PERSONAL.DETAILS')} icon={<UserIcon className="w-5 h-5 text-white" />}>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.CONTACT')}</span>
        <span>{sharedState?.contact_name}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.MOBILE_NO')}</span>
        <span>{sharedState?.mobile_no || NOT_AVALABLE}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.TELEPHONE')}</span>
        <span>{sharedState?.telephone_no}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('REGISTRATION.EMAIL')}</span>
        <span>{sharedState?.email}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('LOGIN.TEXT')}</span>
        <span>{sharedState?.app_login ? YES : NO}</span>
      </div>
      {sharedState?.france_details && (
        <>
          <div className="flex justify-between w-full">
            <span className="font-light">{t('REGISTRATION.DATE_ECHEANCE')}</span>
            <span>{sharedState?.france_details?.date_echeance}</span>
          </div>
          <div className="flex justify-between w-full">
            <span className="font-light">{t('REGISTRATION.NO_CERTIPHYTO')}</span>
            <span>{sharedState?.france_details?.no_certiphyto}</span>
          </div>
        </>
      )}
    </Card>
  );
};

export default PersonalDetails;
