import { classNames } from '../Pages/reviewDetails/components/classNames';

const Card = ({
  children,
  title,
  icon = <></>,
  footerContent = <></>,
  hfull = false,
  className = '',
  titleClassName = '',
}) => {
  return (
    <div className={`${classNames.sectionContainer} ${hfull ? 'h-full' : ''} ${className}`}>
      <div className={`${classNames.sectionTitle} ${titleClassName} group`}>
        <p className={classNames.sectionTitleText}>{title}</p>
        {icon}
      </div>
      <div className={classNames.cardContent}>{children}</div>
      {footerContent}
    </div>
  );
};

export default Card;
