import { useTranslation } from 'react-i18next';
import { classNames } from './classNames';
import Card from '../../../Layout/Card';
import EditButton from './EditButton';
import { useNavigate } from 'react-router-dom';
import { toast, Select, Button } from '@tcc/labz-design-system';
import { useState } from 'react';
import { useStateContext } from '../../../../utils/stateContext';
import GrowerAPI from '../../../../api/grower';

const FieldDetails = ({ fields = [], showContractStatus = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Card
        title={t('FIELD.DETAILS')}
        icon={<EditButton onClick={() => navigate('/home/fielddetails')} />}
        hfull
        className={'pb-0 rounded-bl-md'}
        titleClassName={'mb-0'}
        footerContent={<>{showContractStatus && <CardFooter />}</>}
      >
        <div className={classNames.fieldContainer}>
          {fields?.map((field, index) => (
            <div key={index}>
              <div className={`${classNames.flexGapLarge} bg-gray-200 px-4`}>
                <div>
                  <p className={`${classNames.textSm} text-gray-600 py-1`}>
                    <strong>{field.field_name}</strong>
                  </p>
                </div>
              </div>
              <div className={classNames.flexGap}>
                <div>
                  <p className={classNames.textSm}>
                    <span className={classNames.fieldHeaders}>{t('GENERIC.SIZE')}</span> <br />
                    {field.area}
                  </p>
                </div>
                <div>
                  <p className={classNames.textSm}>
                    <span className={classNames.fieldHeaders}>{t('FIELD.VARIETY')}</span> <br />
                    {typeof field.variety === 'object' ? field.variety.variety_name : field.variety}
                  </p>
                </div>
                <div>
                  <p className={classNames.textSm}>
                    <span className={classNames.fieldHeaders}>{t('FIELD.MIXED')}</span> <br />
                    {field.mixed_variety ? t('GENERIC.YES') : t('GENERIC.NO')}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

const CardFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    initialState: { sharedState },
  } = useStateContext();

  const contractStatusOptions = [
    { id: '1', label: 'Ongoing', value: 'Ongoing' },
    { id: '2', label: 'Signed', value: 'Signed' },
  ];
  const [status, setStatus] = useState(sharedState?.contract || contractStatusOptions[0].value);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (option) => {
    setStatus(option.value);
  };

  const submit = async () => {
    try {
      setIsSaving(true);
      const response = await GrowerAPI.updateGrower({
        uuid: sharedState?.uuid,
        contract: status,
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(t('ALERT.MSG10'));
        setTimeout(() => {
          navigate('/home');
        }, 3000);
      } else {
        toast.error(t('ALERT.MSG21'));
      }
    } catch (error) {
      toast.error(t('ALERT.MSG21'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className={`${classNames.sectionTitle} mb-0 rounded-bl-md`}>
        <span
          className={classNames.sectionTitleText}
        >{`${t('GENERIC.CONTRACT')}  ${t('GENERIC.STATUS')}`}</span>
        <Select
          title=""
          options={contractStatusOptions}
          value={contractStatusOptions.find(
            (contractStatusOption) => status === contractStatusOption.value
          )}
          onChange={handleChange}
          className="m-0 p-0 w-[100px]"
          buttonClassName="w-20 h-8 text-sm"
        />
        <Button
          disabled={isSaving}
          onClick={submit}
          className="flex items-center h-10 text-xs overflow-clip place-self-center"
        >
          {t('GENERIC.SAVE')}
        </Button>
      </div>
    </>
  );
};

export default FieldDetails;
