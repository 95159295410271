import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from './components/Pages/Admin';
import ApiDocsPage from './pages/api-docs';

import Profile from './components/Profile';
import Notifications from './components/Notifications';
import { ProtectedRoutes } from './utils/ProtectedRoutes';
import { StateProvider } from './utils/stateContext';
import HeapAnalytics from './components/HeapAnalytics';

import PageNotFound from './components/Layout/PageNotFound';

import '@tcc/labz-design-system/dist/assets/style.css';
import Login from './components/Pages/Login';
import Home from './components/Pages/Home';
import Registration from './components/Pages/Registration';
import Profile2 from './components/Pages/Profile';
import PasswordReset from './components/Pages/PasswordReset';
import AddGrower from './components/Pages/AddGrower';
import CheckOffer from './components/Pages/checkoffer/CheckOffer';
import GrowerDetail from './components/Pages/growerDetail/GrowerDetail';
import Simulator from './components/Pages/simulator/Simulator';
import FieldDetails from './components/Pages/FieldDetails/FieldDetails';
import DeliveryDetails from './components/Pages/deliveryDetails/DeliveryDetails';
import GrowerOverview from './components/Pages/growerOverview/GrowerOverview';
import ReviewDetails from './components/Pages/reviewDetails/ReviewDetails';

const App = () => (
  <Router>
    <StateProvider>
      <HeapAnalytics />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/home" element={<Admin />} />
          <Route path="/home/:view" element={<Admin />} />
          <Route path="/home2" element={<Home />} />
          <Route path="/api_docs" element={<ApiDocsPage />} />
          <Route path="/home/addgrower" element={<AddGrower />} />
          <Route path="/home/growerdetail" element={<GrowerDetail />} />
          <Route path="/home/checkoffer" element={<CheckOffer />} />
          <Route path="/home/fielddetails" element={<FieldDetails />} />
          <Route path="/home/deliverydetails" element={<DeliveryDetails />} />
          <Route path="/home/reviewdetails" element={<ReviewDetails />} />
          <Route path="/home/growerOverview" element={<GrowerOverview />} />
          <Route path="/home/notifications" element={<Notifications />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/newprofile" element={<Profile2 />} />
          <Route path="/simulator" element={<Simulator />} />
        </Route>
        <Route path="/simulator" element={<Simulator />} />
        <Route path="/register" element={<Registration />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </StateProvider>
  </Router>
);

export default App;
