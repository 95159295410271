import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GrowerAPI from '../../api/grower';
import {
  Box,
  Typography,
  TextField,
  Button,
  Drawer,
  IconButton,
  Divider,
  CircularProgress,
  Stack,
} from '@mui/material';
import { useStateContext } from '../../utils/stateContext';
import SendIcon from '@mui/icons-material/Send';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CommentsFilter from './CommentsFilter';
import { formatDateTime } from '../../utils/commonFunctions';
import dayjs from 'dayjs';
import { toast } from '@tcc/labz-design-system';

const DrawerComponent = ({ openComment, field, onPostComment, setOpenComment }) => {
  const { t } = useTranslation();
  const { userDetails } = useStateContext();
  const [message, setMessage] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [comments, setComments] = useState(null);
  const [filters, setFilters] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async () => {
    if (loading || !message) return;
    setPosting(true);
    try {
      await GrowerAPI.addComment({
        id: field?.id,
        message: message,
        created_by: userDetails.id,
      });
      await handleGetComments(false);
      setMessage('');
      onPostComment();
      toast.success(t('COMPLIANCE.COMMENTS_SAVED'));
    } catch (e) {
      toast.error('ALERT.MSG21');
    } finally {
      setPosting(false);
    }
  };

  const handleApplyFilter = (filters) => {
    setFilters(filters);
    setAnchorEl(null);
  };

  const handleClearFilter = () => {
    setFilters(null);
  };

  const handleGetComments = useCallback(
    async (clearPrevious = true) => {
      try {
        if (clearPrevious) {
          setLoading(true);
          setComments(null);
        }
        handleClearFilter();
        const resp = await GrowerAPI.getComplianceComments(field?.fv_id);
        setComments(resp.compliance_comments);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [field.id]
  );

  useEffect(() => {
    if (field.id) {
      handleGetComments();
      return;
    }
    setComments(null);
  }, [field.id, handleGetComments]);

  const isOpen = !!anchorEl;

  function Comment({ comment: { created_user, created_at, message } }) {
    return (
      <Box sx={{ mt: 0.5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecordIcon sx={{ color: 'lightblue' }} fontSize="small" />
            <Typography
              variant="body2"
              sx={{ fontWeight: 550, fontSize: 12, textTransform: 'capitalize' }}
            >
              {created_user}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ fontSize: 12, mr: 1 }}>
            {formatDateTime(created_at)}
          </Typography>
        </Box>
        <Typography sx={{ ml: 4, fontSize: 12, overflowWrap: 'break-word' }}>
          {message ?? '--'}
        </Typography>
        <Divider component="hr" sx={{ mt: 0.5 }} />
      </Box>
    );
  }

  const getFilteredComments = (criteria, comments) => {
    const lowerCaseKeyword = criteria?.keywords?.toLowerCase();
    const selectedDate = criteria?.date ? dayjs(criteria.date) : null;
    const keywordsFiltered = lowerCaseKeyword
      ? (comments ?? []).filter((row) =>
          Object.values(row).some(
            (value) => value && value.toString().toLowerCase().includes(lowerCaseKeyword)
          )
        )
      : comments;
    const dateFiltered = selectedDate
      ? (keywordsFiltered ?? []).filter((row) => {
          const rowDate = dayjs(row.created_at);
          return rowDate.isSame(selectedDate, 'day');
        })
      : keywordsFiltered;

    return dateFiltered;
  };

  function CommentList() {
    const list = !!filters ? getFilteredComments(filters, comments) : comments;
    const sortedList = (list ?? []).sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );

    return (
      <>
        {!sortedList.length && (
          <Stack
            direction={'column'}
            sx={{ justifyContent: 'center', alignItems: 'center', height: '100%', opacity: 0.5 }}
          >
            <ForumOutlined color="disabled" sx={{ width: '7em', height: '7em' }} />
            <Typography variant="body1" sx={{ fontWeight: 550 }}>
              {loading ? t('COMPLIANCE.LOADING_COMMENTS') : t('COMPLIANCE.NO_COMMENTS')}...
            </Typography>
          </Stack>
        )}
        {sortedList.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </>
    );
  }

  return (
    <Box>
      <Drawer anchor={'right'} open={openComment} onClose={() => setOpenComment(false)}>
        <Box sx={{ width: 350, height: '92%', ml: 2, mt: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 550 }}>
            {field?.field_name}
          </Typography>
          {field?.compliance ? (
            <>
              <Typography variant="body2" sx={{ color: '#FF3162' }}>
                {t('COMPLIANCE.FIELD_COMPLIES_NOT')}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 550 }}>
                {t('COMPLIANCE.NON_COMPLIANCE_RULES_TITLE')}
              </Typography>
              <Typography variant="body2">- {t('COMPLIANCE.RULES_BBCH')}</Typography>
              <Typography variant="body2">- {t('COMPLIANCE.RULES_APPLICATION_PERIOD')}</Typography>
              <Typography variant="body2">
                {t('COMPLIANCE.RULES_RECOMMENDED_SPRAY_DATE')}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" sx={{ color: '#66B512' }}>
              {t('COMPLIANCE.FIELD_COMPLIES')}
            </Typography>
          )}
          <Divider component="hr" sx={{ border: '1px solid #B5CDD7', mt: 1 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: 1,
              mb: 1,
            }}
          >
            <Typography sx={{ fontWeight: 550, fontSize: 14 }}>
              {t('COMPLIANCE.COMMENTS_TITLE')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '1vw' }}>
              {!loading && (
                <Button
                  sx={{ borderRadius: '5px', border: '1px solid #CCCCCC', padding: 0, minWidth: 0 }}
                  onClick={() => handleGetComments()}
                >
                  <RefreshOutlinedIcon />
                </Button>
              )}
              {loading && <CircularProgress size="24px" />}
              <Button
                sx={{
                  borderRadius: '5px',
                  border: '1px solid #CCCCCC',
                  padding: 0,
                  minWidth: 0,
                  mr: 1,
                }}
              >
                <SearchOutlinedIcon
                  aria-controls={isOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isOpen ? 'true' : undefined}
                  onClick={handleClick}
                />
              </Button>
              <CommentsFilter
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                onApply={(filters) => handleApplyFilter(filters)}
              />
            </Box>
          </Box>
          <Box sx={{ height: '84%', overflow: 'scroll' }}>
            <CommentList />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: '#0B617F', position: 'absolute', bottom: 0, width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              m: 1,
              backgroundColor: 'white',
            }}
          >
            <TextField
              multiline
              variant="standard"
              placeholder={t('COMPLIANCE.COMMENTS_TITLE')}
              size="small"
              sx={{ width: '100%', border: 'none', ml: 1 }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={posting || loading}
            ></TextField>
            <IconButton onClick={() => handleSubmit()} disabled={loading || posting}>
              {!posting && <SendIcon color={loading || posting ? 'disabled' : 'primary'} />}
              {posting && <CircularProgress size="24px" />}
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};
export default DrawerComponent;
