import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import useAgronomy from '../hooks/useAgronomy';
import useLogistic from '../hooks/useLogistic';
import useOffers from '../hooks/offers/useOffers';
import useSubscription from '../hooks/useSubscription';
import { toast } from '@tcc/labz-design-system';
import { useTranslation } from 'react-i18next';

const StateContext = createContext<any>(null);

export function StateProvider({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [sharedState, setSharedState] = useState({});
  const [list, setList] = useState([]);
  const [agronomyList, setAgronomyList] = useState([]);

  const [rows, setRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedSalesPersons, setSelectedSalesPersons] = useState([]);
  const [growers, setGrowers] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editFromGrower, setEditFromGrower] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedField, setSelectedField] = useState({});
  const [openComment, setOpenComment] = useState(false);

  const [comments, setComments] = useState([]);

  const agronomy = useAgronomy();
  const logistic = useLogistic();

  const defaultState = {
    sharedState: {},
    selectedRows: [],
    selectedIndexes: [],
    growerState: {},
  };

  const [initialState, setInitialState] = useState(defaultState);

  const getTotalArea = () => {
    let totalArea = 0;
    initialState?.selectedRows.forEach((row) => {
      totalArea += row.area;
    });
    return totalArea.toFixed(2);
  };

  const [growerState, setGrowerState] = useState({});
  const handleCheckboxClick = (row) => {
    const selectedIndex = selectedIndexes.indexOf(row.id);
    let newSelected = [];
    let newIndex = [];
    if (selectedIndex === -1) {
      if (selectedRows.length >= 15) {
        toast.error(t('GROWER.MAX_FIELD_SIZE'));
        return null;
      }
      row.address = 'add';
      newSelected = [...selectedRows, row];
      newIndex = [...selectedIndexes, row.id];
    } else {
      row.address = 'sub';
      newSelected = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ];
      newIndex = [
        ...selectedIndexes.slice(0, selectedIndex),
        ...selectedIndexes.slice(selectedIndex + 1),
      ];
    }
    setSelectedIndexes(newIndex);
    setSelectedRows(newSelected);
    setInitialState({ ...initialState, selectedRows: newSelected, selectedIndexes: newIndex });
  };

  const handleFieldsUpdate = (index) => (e) => {
    if (e.target.name === 'tillage') {
      const newArray = initialState?.selectedRows.map((item, i) => {
        if (index === i) {
          return { ...item, [e.target.name]: e.target.checked };
        } else {
          return item;
        }
      });
      setSelectedRows(newArray);
      setInitialState({ ...initialState, selectedRows: newArray });
    } else {
      const newArray = initialState?.selectedRows.map((item, i) => {
        if (index === i) {
          return { ...item, [e.target.name ? e.target.name : e.target.id]: e.target.value };
        } else {
          return item;
        }
      });
      setSelectedRows(newArray);
      setInitialState({ ...initialState, selectedRows: newArray });
    }
  };

  const handleDateUpdate = (newValue, index) => {
    const newArray = initialState?.selectedRows.map((item, i) => {
      if (index === i) {
        return { ...item, sowing_date: dayjs(newValue).format('DD-MM-YYYY') };
      } else {
        return item;
      }
    });
    setSelectedRows(newArray);
    setInitialState({ ...initialState, selectedRows: newArray });
  };

  const handleOptionChange = (index, newValue, name) => {
    const newArray = initialState?.selectedRows.map((item, i) => {
      if (index === i) {
        if (name === 'soil') {
          return { ...item, soil_type: newValue?.nom_arvalis };
        }

        if (name === 'region') {
          return { ...item, [name]: newValue, soil_type: '' };
        } else {
          return { ...item, [name]: newValue };
        }
      } else {
        return item;
      }
    });
    setSelectedRows(newArray);
    setInitialState({ ...initialState, selectedRows: newArray });
  };

  const handleGrowerUpdate = (e) => {
    setGrowerState((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    setInitialState({
      ...initialState,
      growerState: { ...growerState, [e.target.name]: e.target.value },
    });
  };
  const handleSalesRepCheckboxClick = ({ id }) => {
    const selectedIndex = selectedSalesPersons.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedSalesPersons, id];
    } else {
      newSelected = [
        ...selectedSalesPersons.slice(0, selectedIndex),
        ...selectedSalesPersons.slice(selectedIndex + 1),
      ];
    }

    setSelectedSalesPersons(newSelected);
  };

  const handleGrowerCheckboxClick = ({ email }) => {
    const selectedIndex = growers.indexOf(email);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...growers, email];
    } else {
      newSelected = [...growers.slice(0, selectedIndex), ...growers.slice(selectedIndex + 1)];
    }

    setGrowers(newSelected);
  };

  // I don't see any place where this is used. There's setUser used in login.tsx
  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
  };

  const updateSharedState = async (newState) => {
    if (newState?.order) {
      await setSharedState({ ...sharedState, ...newState });
      await setInitialState({ ...initialState, sharedState: { ...newState } });
      await navigate('/home/growerOverview');
      return;
    } else {
      await setSharedState({ ...sharedState, ...newState });
      await setInitialState({ ...initialState, sharedState: { ...newState } });
      await navigate('/home/growerdetail');
    }
  };

  function validateMandatoryProperties(array, mandatoryProperties) {
    return array.reduce((isValid, obj) => {
      if (!isValid) {
        return false;
      }
      for (const prop of mandatoryProperties) {
        if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
          return false;
        }
      }
      return true;
    }, true);
  }

  const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const userDetails = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails'))
    : null;
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
  const local = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : null;
  const [editFromReview, setEditFromReview] = useState(false);

  const subscription = useSubscription();
  const offers = useOffers({ distributorId: userDetails?.distributor?.id });

  return (
    <StateContext.Provider
      value={{
        offers,
        local,
        sharedState,
        updateSharedState,
        list,
        setList,
        subscription,
        user,
        setUser,
        userData,
        userDetails,
        login,
        logistic,
        logout,
        rows,
        setRows,
        selectedRows,
        setSelectedRows,
        handleCheckboxClick,
        getTotalArea,
        selectedSalesPersons,
        setSelectedSalesPersons,
        handleSalesRepCheckboxClick,
        handleFieldsUpdate,
        growerState,
        setGrowerState,
        handleGrowerUpdate,
        handleDateUpdate,
        handleOptionChange,
        editable,
        setEditable,
        editFromGrower,
        setEditFromGrower,
        validateMandatoryProperties,
        editFromReview,
        setEditFromReview,
        token,
        initialState,
        setInitialState,
        defaultState,
        setSelectedIndexes,
        growers,
        setGrowers,
        handleGrowerCheckboxClick,
        agronomyList,
        setAgronomyList,
        notifications,
        setNotifications,
        rowsPerPage,
        setRowsPerPage,
        selectedField,
        setSelectedField,
        openComment,
        setOpenComment,
        comments,
        setComments,
        agronomy,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
  return useContext(StateContext);
}
