import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../utils/stateContext';
import Drawer from '../Table/Drawer';
import Shell from '../Layout/shared/Shell';
import Agronomy from './agronomy/Agronomy';
import Logistic from './logistic/Logistic';
import Subscription from './subscription/Subscription';
import { useParams } from 'react-router-dom';

const PageContent = () => {
  const { view = 'subscription' } = useParams();
  const { t } = useTranslation();

  const {
    subscription: { subscriptionList },
    setSelectedSalesPersons,
    setSelectedRows,
    setRows,
    setGrowerState,
    userDetails,
    setInitialState,
    defaultState,
    setSelectedIndexes,
    getData,
    selectedField,
    setOpenComment,
    openComment,
  } = useStateContext();

  useEffect(() => {
    if (subscriptionList.length <= 0) {
      setSelectedRows([]);
      setRows([]);
      setGrowerState({});
      setSelectedIndexes([]);
      setInitialState(defaultState);
      setSelectedSalesPersons([]);
    }
  }, []);

  if (userDetails?.role === 100) return <div>{t('ALERT.MSG19')}</div>;

  const routeComponents = {
    subscription: <Subscription />,
    agronomy: <Agronomy />,
    logistic: <Logistic />,
  };

  return (
    <>
      {routeComponents[view]}
      <Drawer
        openComment={openComment}
        setOpenComment={setOpenComment}
        field={selectedField}
        onPostComment={getData}
      />
    </>
  );
};

const Admin = () => {
  const {
    subscription: {
      getAllGrowers: [, , fetchingGrowers, fetchAllGrowers],
    },
  } = useStateContext();

  useEffect(() => {
    fetchAllGrowers();
  }, []);

  return (
    <Shell footer={false} loading={fetchingGrowers}>
      <PageContent />
    </Shell>
  );
};

export default Admin;
