import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';

const SoilTypeSelect = ({ value, soilTypes, onChange, region, className }) => {
  return (
    <Autocomplete
      className={className}
      value={soilTypes.find((soil) => soil.id === value) || null}
      onChange={(_, newValue) => {
        onChange(newValue?.id || null);
      }}
      options={soilTypes.filter((x) => x.region === region)}
      getOptionLabel={(option) => option.arvalis_name || ''}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          label={`${t('FIELD.SOIL_TYPE')}*`}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
};

export default SoilTypeSelect;
