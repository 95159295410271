import { Button } from '@tcc/labz-design-system';
import PredimaOfferLogo from './PredimaOfferLogo';
import { useStateContext } from '../../../utils/stateContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const classNames = {
  offerFooter:
    'flex w-full p-4 mt-4 justify-between items-center border rounded-xl border-gray-300 relative',
  offerFooterSection: 'flex h-full flex-col items-center flex-1 relative justify-center',
  fieldNames: 'text-text-1 w-[300px] font-light whitespace-nowrap overflow-hidden text-ellipsis',
  price: 'text-4xl text-primary-600 font-bold',
  totalPriceEuroSign: 'text-text-1 text-sm font-light mr-2',
  summary: 'text-[12px] md:text-[14px] lg:text-[16px] text-center',
  divider: 'absolute w-[1px] top-0 bottom-0 right-0 bg-gray-300',
};

const OfferFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    offers: { selectedOffer, totalPrice, selectedFields },
  } = useStateContext();

  const handleProceedClick = () => {
    navigate('/home/fielddetails');
  };

  return (
    <div className={classNames.offerFooter}>
      <div className={classNames.offerFooterSection} style={{ position: 'relative' }}>
        <PredimaOfferLogo offerName={selectedOffer?.name} />
        <span className={classNames.divider}></span>
      </div>
      <div className={classNames.offerFooterSection} style={{ position: 'relative' }}>
        <span className={classNames.price}>
          <span className={classNames.totalPriceEuroSign}>€</span>
          {totalPrice}
        </span>
        <span className={classNames.summary}>{t('OFFERS.FOOTER.PRICE_TOTAL_AREA')}</span>
        <span className={classNames.divider}></span>
      </div>
      <div className={`${classNames.offerFooterSection} pl-2`}>
        <Button
          className="w-full"
          disabled={!selectedFields.length || !selectedOffer}
          onClick={handleProceedClick}
        >
          {t('OFFERS.BUTTONS.PROCEED')}
        </Button>
      </div>
    </div>
  );
};

export default OfferFooter;
