import { useTranslation } from 'react-i18next';
import React from 'react';
import { useStateContext } from '../../utils/stateContext';
import ActualSprayDateModal from './ActualSprayDateModal';
import './agronomy.css';
import PredimaTable from '../PredimaTable';

const columnWidth = `${100 / 15}%`;

const Application = () => {
  const { t } = useTranslation();
  const {
    subscription: { subscriptionList },
    agronomy: {
      ActualSprayDateModal: [, { on: openActualSprayDateModal }],
      selectedField: [, setSelectedField],
      selectedTreatment: [, setSelectedTreatment],
      SearchBar: [isSearchBarOpen],
    },
  } = useStateContext();

  const handleUpdateTreatment = (rowData, t_window) => {
    const selectedTreatment = rowData?.treatments?.[t_window];
    setSelectedTreatment({
      ...selectedTreatment,
      t_window,
    });
    setSelectedField(rowData);
    openActualSprayDateModal();
  };

  const columns = [
    {
      name: 'distributor',
      label: t('GENERIC.DISTRIBUTOR'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'created_user_name',
      label: t('HOME.SALES_REP_NAME'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'contact_name',
      label: 'Grower Name',
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'name',
      label: t('GENERIC.FARM'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'field_name',
      label: t('GENERIC.FIELD'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'field_area',
      label: t('AGRONOMY.HECTARE'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    { name: 'T1', colSpan: 3, groupHeader: true, color: '#02A8A8' },
    { name: 'T2', colSpan: 3, groupHeader: true, color: '#00A6E1' },
    { name: 'T3', colSpan: 3, groupHeader: true, color: '#01849D' },
    {
      name: 'treatments.T1.treatment_date',
      label: t('AGRONOMY.ACT_DATE'),
      color: '#02A8A8',
      group: true,
      date: true,
      treatment: true,
      tWindow: 'T1',
      inputFormat: 'date',
      width: columnWidth,
      onClick: (rowData) => handleUpdateTreatment(rowData, 'T1'),
    },
    {
      name: 'rec_spray_date.T1.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#02A8A8',
      group: true,
      date: true,
      treatment: true,
      tWindow: 'T1',
      inputFormat: 'date',
      width: columnWidth,
    },
    {
      name: 'treatments.T1.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#02A8A8',
      search: true,
      group: true,
      treatment: true,
      inputFormat: 'string',
      tWindow: 'T1',
      width: columnWidth,
    },
    {
      name: 'treatments.T2.treatment_date',
      label: t('AGRONOMY.ACT_DATE'),
      color: '#00A6E1',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T2',
      width: columnWidth,
      onClick: (rowData) => handleUpdateTreatment(rowData, 'T2'),
    },
    {
      name: 'rec_spray_date.T2.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#00A6E1',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T2',
      width: columnWidth,
    },
    {
      name: 'treatments.T2.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#00A6E1',
      search: true,
      group: true,
      tWindow: 'T2',
      treatment: true,
      inputFormat: 'string',
      width: columnWidth,
    },
    {
      name: 'treatments.T3.treatment_date',
      label: t('AGRONOMY.ACT_DATE'),
      color: '#01849D',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T3',
      width: columnWidth,
      onClick: (rowData) => handleUpdateTreatment(rowData, 'T3'),
    },
    {
      name: 'rec_spray_date.T3.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#01849D',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T3',
      width: columnWidth,
    },
    {
      name: 'treatments.T3.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#01849D',
      width: columnWidth,
      search: true,
      inputFormat: 'string',
      treatment: true,
      tWindow: 'T3',
      group: true,
    },
  ];

  return (
    <div>
      <ActualSprayDateModal />
      <PredimaTable
        data={subscriptionList}
        columns={columns}
        selectable={false}
        showFilters={isSearchBarOpen}
      />
    </div>
  );
};

export default Application;
