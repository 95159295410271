import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../utils/stateContext';
import ColumnsLayout from '../../Layout/shared/page/ColumnsLayout';
import FieldDetails from '../reviewDetails/components/FieldDetails';
import Card from '../../Layout/Card';
import Eye from '../../icons/Eye';
import PersonalDetails from './components/PersonalDetails';
import FarmDetails from './components/FarmDetails';
import DeliveryDetails from './components/DeliveryDetails';

const Overview = () => {
  const { t } = useTranslation();
  const {
    initialState: { sharedState },
  } = useStateContext();

  return (
    <Card title={t('GENERIC.OVERVIEW')} icon={<Eye className="w-5 h-5 text-white" />}>
      <div className="flex justify-between items-center w-full">
        <span className="font-light">{t('TOTAL.FIELDS')}</span>
        <span className={'font-bold text-lg'}>{sharedState?.order?.order_total_no_fields}</span>
      </div>
      <div className="flex justify-between items-center w-full">
        <span className="font-light">{t('TOTAL.HECTARES')}</span>
        <span className={'font-bold text-lg'}>{sharedState?.order?.order_total_ha}</span>
      </div>
      <div className="flex justify-between items-center w-full">
        <span className="font-light">{t('TOTAL.PRICE')}</span>
        <span className={'font-bold text-lg'}>
          <span className="text-sm">EUR</span> {sharedState?.order?.order_price.toFixed(2)}
        </span>
      </div>
    </Card>
  );
};

const Page = () => {
  const {
    initialState: { sharedState },
  } = useStateContext();

  return (
    <ColumnsLayout>
      <FieldDetails fields={sharedState?.order?.order_fields} showContractStatus={true} />
      <div className="gap-8 grid grid-cols-2 grid-rows-[auto] my-[53px] px-8">
        <Overview />
        <PersonalDetails />
        <FarmDetails />
        <DeliveryDetails />
      </div>
    </ColumnsLayout>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const GROWER_OVERVIEW = t('GROWER_OVERVIEW');
  const tabs = [
    {
      id: 'grower_overview',
      name: GROWER_OVERVIEW,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const GrowerOverview = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default GrowerOverview;
