export const defaultData = [
  {
    field_id: 1,
    item_status: 'ACTIVE',
    area: 13.16,
    field_name: 'kazlak',
    variety: '',
    soil_type_id: 999,
    sowing_date: '',
    mixed_variety: '',
    region: '',
    user_email: 'attila.hajdu@climate.com',
    id: 632,
    country_code: 'HU',
    centroid_coord: '18.85754870379993, 46.08119041468179',
    address: null,
    previous_crop: 'Peas',
    tillage: false,
    crop: 'Wheat',
  },
  {
    field_id: 2,
    item_status: 'ACTIVE',
    area: 13.29,
    field_name: 'tuna_2017',
    variety: '',
    soil_type_id: 999,
    sowing_date: '',
    mixed_variety: '',
    region: '',
    user_email: 'attila.hajdu@climate.com',
    id: 631,
    country_code: 'RS',
    centroid_coord: '19.063723525987733, 45.99351536604761',
    address: null,
    previous_crop: 'Sunflower',
    tillage: false,
    crop: 'Wheat',
  },
];
