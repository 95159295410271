import React from 'react';
import PredimaLogo from '../../icons/PredimaLogo';

const classNames = {
  predimaLogo: 'w-12 h-12',
  predimaTitle:
    'flex flex-col xl:flex-row font-helvetica font-light text-[12px] md:text-[16px] text-text-1 font-weight italic tracking-widest',
  offerName: 'font-bold text-lg ml-1 text-[12px] md:text-[16px]',
};

interface PredimaOfferLogoProps {
  offerName: 'Leaf' | 'One' | 'Flexx';
}

const PredimaOfferLogo = ({ offerName }: PredimaOfferLogoProps) => {
  return (
    <div className={'flex gap-1 items-center'}>
      <PredimaLogo className={classNames.predimaLogo} />
      <span className={classNames.predimaTitle}>
        PreDiMa
        <span className={classNames.offerName}>{offerName?.split(' ')[1]}</span>
        {!offerName && <span>--</span>}
      </span>
    </div>
  );
};

export default PredimaOfferLogo;
