import React from 'react';
import { useTranslation } from 'react-i18next';
import SoilTypeSelect from '../../Forms/SoilTypeSelect';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  Typography,
  Select,
} from '@mui/material';
import { BpCheckbox, ISODateFormat } from '../../../utils/commonFunctions';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormat } from '../../../utils/commonFunctions';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import VarietiesSelect from '../../Forms/VarietiesSelect';
import RegionSelect from '../../Forms/RegionSelect';
import { DefaultValues, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { components } from '../../../api/client/schema';

const classNames = {
  formContainer: 'formContainer mt-4 border border-text-3 rounded-md',
  form: 'flex flex-col mt-4',
  formRow: 'flex gap-4 justify-between px-4',
  headerSection: 'w-[33%]',
  fieldHeader: 'flex gap-4 text-sm py-2 bg-[#F0F9FD] px-4',
  fieldDivider: 'border-r border-gray-300',
  formDivider: 'border-t border-gray-300 my-2',
  formGrid: 'flex gap-4',
  soilInfoBox: 'bg-blue-50 p-4 mt-2',
  soilInfoTitle: 'font-medium text-sm',
  soilInfoText: 'text-sm',
};

type FieldFormValues = {
  region: string;
  variety: string;
  mixed_variety: boolean;
  soil_type_id: number;
  sowing_date: Date;
  previous_crop: string;
  tillage: boolean;
  soil_type: components['schemas']['SoilTypeOut'] | null;
};

interface FieldFormProps {
  field: any;
  varieties: any[];
  soilTypes: any[];
}

export interface FieldFormRef {
  triggerValidation: () => Promise<{ isValid: boolean; data: FieldFormValues }>;
}

const FieldForm = forwardRef<FieldFormRef, FieldFormProps>(
  ({ field, varieties, soilTypes }, ref) => {
    const { t } = useTranslation();
    const local = localStorage.getItem('locale')
      ? JSON.parse(localStorage.getItem('locale'))
      : null;
    const editable = true;

    const validationSchema = Yup.object().shape({
      region: Yup.string().required(t('FIELD.DETAILS.REGION.REQUIRED')),
      variety: Yup.string().required(t('FIELD.DETAILS.VARIETY.REQUIRED')),
      mixed_variety: Yup.boolean(),
      soil_type_id: Yup.number().required(t('FIELD.DETAILS.SOIL_TYPE.REQUIRED')),
      sowing_date: Yup.date().required(t('FIELD.DETAILS.SOWING_DATE.REQUIRED')),
      previous_crop: Yup.string().required(t('FIELD.DETAILS.PREVIOUS_CROP.REQUIRED')),
      tillage: Yup.boolean(),
    });

    const defaultValues: DefaultValues<FieldFormValues> = useMemo(
      () => ({
        region: field.region || '',
        variety: field.variety || '',
        mixed_variety: field.mixed_variety || false,
        soil_type_id: field.soil_type_id || '',
        soil_code: field.soil_code || '',
        sowing_date: field.sowing_date ? new Date(field.sowing_date) : null,
        previous_crop: field.previous_crop || '',
        tillage: field.tillage || false,
      }),
      [field]
    );

    const {
      formState: { errors },
      setValue,
      getValues,
      watch,
      trigger,
    } = useForm({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });

    useImperativeHandle(ref, () => ({
      triggerValidation: async () => {
        const result = await trigger();
        const formData = getValues() as FieldFormValues;
        return { isValid: result, data: { ...field, ...formData } };
      },
    }));

    watch('region');
    watch('variety');
    watch('previous_crop');
    watch('mixed_variety');
    watch('tillage');
    watch('sowing_date');
    watch('soil_type_id');
    const sowing_date = getValues('sowing_date');

    const varietyValue = getValues('variety');

    return (
      <div className={classNames.formContainer}>
        <div className={classNames.fieldHeader}>
          <span className={classNames.headerSection}>
            {t('GENERIC.FIELD')} <br />
            <b>{field.field_name}</b>
          </span>
          <span className={classNames.fieldDivider} />
          <span className={classNames.headerSection}>
            {`${t('GENERIC.SIZE')} (HA)`} <br />
            {field.area}
          </span>
          <span className={classNames.fieldDivider} />
          <span className={classNames.headerSection}>
            {t('GROWER.CROP_TYPE')} <br />
            {t('FIELD.DETAILS.WHEAT')}
          </span>
        </div>
        <form className={classNames.form}>
          <div className={classNames.formRow}>
            <VarietiesSelect
              className={'w-1/6'}
              varieties={varieties}
              value={varietyValue}
              onChange={(_, variety) => setValue('variety', variety)}
            />
            <RegionSelect
              className={'w-1/6'}
              value={getValues('region')}
              onChange={(_, region) => {
                setValue('region', region);
                setValue('soil_type_id', null);
              }}
              soilTypes={soilTypes}
            />
            <SoilTypeSelect
              className={'w-2/6'}
              value={getValues('soil_type_id')}
              soilTypes={soilTypes}
              onChange={(newValue) => {
                setValue('soil_type_id', newValue);
              }}
              region={getValues('region')}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={'w-1/6'}
                label={`${t('FIELD.SOWING_DATE')}*`}
                value={
                  sowing_date === null
                    ? dayjs(null)
                    : editable
                      ? dayjs(sowing_date)
                      : (dayjs(sowing_date) as any)
                }
                format={dateFormat}
                minDate={dayjs('01-01-2024', dateFormat) as any}
                maxDate={dayjs('09-01-2025', dateFormat) as any}
                onChange={(newValue) => {
                  const dateOnly = newValue ? newValue.startOf('day').format(ISODateFormat) : null;
                  setValue('sowing_date', dateOnly);
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: false,
                    error: errors.sowing_date ? true : false,
                  },
                }}
              />
            </LocalizationProvider>
            <FormControl size="small" className={'w-1/6'}>
              <InputLabel>{`${t('FIELD.PREVIOUS_CROP')}*`}</InputLabel>
              <Select
                value={getValues('previous_crop') || ''}
                label={`${t('FIELD.PREVIOUS_CROP')}*`}
                name="previous_crop"
                onChange={(s) => setValue('previous_crop', s.target.value)}
              >
                <MenuItem value="Wheat">
                  {local !== 'fr' ? t('FIELD.DETAILS.WHEAT') : 'Blé'}
                </MenuItem>
                <MenuItem value="Barley">{local !== 'fr' ? 'Barley' : 'Orge'}</MenuItem>
                <MenuItem value="Grain Corn">
                  {local !== 'fr' ? 'Grain Corn' : 'Mais Grain'}
                </MenuItem>
                <MenuItem value="Corn silage">
                  {local !== 'fr' ? 'Corn silage' : 'Mais Ensilage'}
                </MenuItem>
                <MenuItem value="Oilseed rape">
                  {local !== 'fr' ? 'Oilseed rape' : 'Colza'}
                </MenuItem>
                <MenuItem value="Peas">{local !== 'fr' ? 'Peas' : 'Pois'}</MenuItem>
                <MenuItem value="Sunflower">{local !== 'fr' ? 'Sunflower' : 'Tournesol'}</MenuItem>
                <MenuItem value="Sorghum">{local !== 'fr' ? 'Sorghum' : 'Sorgho'}</MenuItem>
                <MenuItem value="Others">{local !== 'fr' ? 'Others' : 'Autres'}</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={getValues('tillage')}
                  onChange={(a) => setValue('tillage', !getValues('tillage'))}
                  name="tillage"
                  color="primary"
                />
              }
              label={<Typography variant="body2">{t('FIELD.TILLAGE')}</Typography>}
            />
          </div>
          <div className={classNames.formRow}>
            <FormControlLabel
              control={
                <BpCheckbox
                  name="mixed_variety"
                  checked={getValues('mixed_variety') || false}
                  onChange={(event, newValue) => setValue('mixed_variety', newValue)}
                />
              }
              label={<Typography sx={{ fontSize: '12px' }}>{t('FIELD.MIXED_VARIETY')}</Typography>}
            />
          </div>
        </form>
      </div>
    );
  }
);

export default FieldForm;
