import { DatePicker } from '@tcc/labz-design-system';

import React, { ReactNode } from 'react';

const classNames = {
  inputContainer: 'flex flex-col w-full relative',
  inputLabel: 'flex font-helvetica text-[#333333]',
  fieldError: 'text-red-600 text-sm absolute right-0 bottom-[-22px]',
  input: 'w-full',
};

type FormDateInputProps = {
  label?: string;
  type?: string;
  required?: boolean;
  onFocus?: () => void;
  field: any;
  disabled?: boolean;
  TrailingIcon?: ReactNode;
};

const FormDateInput: React.FC<FormDateInputProps> = ({ field, label, required = true }) => {
  const { name, trigger, setValue, getValues, error } = field;
  const handleChange = (e) => {
    if (e) {
      setValue(e);
      trigger(name);
    }
  };

  const value = getValues(name);

  return (
    <div className={classNames.inputContainer}>
      <span className={classNames.inputLabel}>
        {label}
        {required && <>*</>}
      </span>
      <DatePicker
        title={``}
        value={value}
        className={`${classNames.input} mt-[-4px]`}
        onChange={handleChange}
        locale={'es'}
      />
      <span className={classNames.fieldError}>{error?.message}</span>
    </div>
  );
};

export default FormDateInput;
