import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import useVarietiesSoilTypes from '../../../hooks/useVarietiesSoilTypes';
import { useStateContext } from '../../../utils/stateContext';
import FieldForm, { FieldFormRef } from '../../Layout/checkoffer/FieldForm';
import { useRef } from 'react';
import BackNextButtons from '../../Layout/checkoffer/BackNextButtons';
import { useTranslation } from 'react-i18next';

const classNames = {
  container: 'flex flex-col w-full min-h-[559px] flex-grow gap-4 p-4',
  buttonsContainer: 'flex justify-between',
  proceedButton: 'w-[200px] place-self-end',
  backButton: 'w-[200px] place-self-start',
  welcomeText: 'text-text-1 font-light',
};

const Page = () => {
  const { varieties, soilTypes } = useVarietiesSoilTypes();
  const {
    offers: { selectedFields, updatingFieldviewData, updateFieldviewData, setSelectedFields },
  } = useStateContext();

  const formRefs = useRef<(FieldFormRef | null)[]>([]);

  const validateAllForms = async () => {
    const validationResults = await Promise.all(
      formRefs.current.map((ref) => {
        if (ref) {
          return ref.triggerValidation().then(({ isValid, data }) => {
            return { isValid, data };
          });
        }
        return Promise.resolve({ isValid: false, data: null });
      })
    );

    const allValid = validationResults.every(({ isValid }) => isValid === true);

    if (allValid) {
      const allFormsData = validationResults.map(({ data }) => data);
      updateFieldviewData(allFormsData);
      setSelectedFields(allFormsData);
    }

    return allValid;
  };

  return (
    <div className={classNames.container}>
      <span className={classNames.welcomeText}>Kindly Update fields information:</span>
      {selectedFields.map((field, index) => (
        <FieldForm
          key={field.id}
          ref={(el) => {
            formRefs.current[index] = el;
          }}
          field={field}
          varieties={varieties}
          soilTypes={soilTypes}
        />
      ))}
      <BackNextButtons onNextClick={validateAllForms} loading={updatingFieldviewData} />
    </div>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const FIELD_DETAILS = t('FIELD_DETAILS');

  const tabs = [
    {
      id: 'field_details',
      name: FIELD_DETAILS,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const FieldDetails = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default FieldDetails;
