import { classNames } from '../../Pages/reviewDetails/components/classNames';

const Layout3Cols = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-4 mb-8 px-8 h-full min-h-[600px] grow Layout3cols">
      <span className={`${classNames.noteText} mt-4`}>{title}</span>
      <div className="flex justify-between gap-8 w-full h-full grow">
        {children[0]}
        {children[1]}
        {children[2]}
      </div>
    </div>
  );
};

export default Layout3Cols;
