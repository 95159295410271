import React from 'react';
import { InputBase } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const TextFilter = ({ onChange, value }) => {
  return (
    <InputBase
      sx={{
        border: '1px solid #0091DF',
        borderRadius: '5px',
      }}
      size="small"
      value={value}
      onChange={onChange}
      endAdornment={<SearchOutlinedIcon fontSize="small" />}
    />
  );
};

export default TextFilter;
