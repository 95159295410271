export enum PDWindow {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
}

export interface ProductItem {
  created_user_name: string;
  grower_name: string;
  farm_name: string;
  total_area: number;
  order_id: number;
  T1dosage?: string;
  T1id?: number;
  T1pd_window?: PDWindow;
  T1product?: string;
  T1sent?: boolean;
  T2dosage?: string;
  T2id?: number;
  T2pd_window?: PDWindow;
  T2product?: string;
  T2sent?: boolean;
  T3dosage?: string;
  T3id?: number;
  T3pd_window?: PDWindow;
  T3product?: string;
  T3sent?: boolean;
}
