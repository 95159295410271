import React from 'react';
import { classNames } from '../Pages/reviewDetails/components/classNames';

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${classNames.buttonIcon} text-transparent group-hover:text-white`}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      {/* SVG path for the edit icon */}
      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
      <path
        fillRule="evenodd"
        d="M4 15a1 1 0 01-1-1v-1.586a1 1 0 01.293-.707l8-8a1 1 0 011.414 1.414l-8 8A1 1 0 014 13.414V14h1.586a1 1 0 01.707.293l8 8a1 1 0 01-1.414 1.414l-8-8A1 1 0 014 15z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default EditIcon;
