import { Table, BaseCell, Checkbox } from '@tcc/labz-design-system';
import { useStateContext } from '../../../utils/stateContext';
import ImportFieldviewDataButton from './ImportFieldviewDataButton';
import useImportFieldviewData from '../../../hooks/useImportFieldviewData';
import { useTranslation } from 'react-i18next';

const classNames = {
  tableContainer:
    'flex min-h-[700px] flex-col w-[600px] lg:w-[500px] h-[100px] overflow-auto relative bg-background-1 z-20',
  tableOverlay: 'flex absolute items-center justify-center w-full h-full bg-background-1 z-20',
};

type FieldData = {
  id: number;
  fieldName: string;
  ha: number;
};
const GrowerDetailTable = () => {
  const {
    rows,
    offers: { isFieldSelected, toggleSelectField, totalArea },
  } = useStateContext();
  const { t } = useTranslation('', { keyPrefix: 'GENERIC' });
  const FIELD_NAME = t('FIELD');
  const { loadingFieldViewData, importData } = useImportFieldviewData();

  const COLUMNS = [
    {
      label: FIELD_NAME,
      key: 'field_name',
    },
    {
      label: 'Ha',
      key: 'area',
    },
    {
      label: '--',
      key: 'check',
    },
  ];

  const COLUMNS_FOOTER = [
    {
      label: 'Total area',
      key: 'name',
    },
    {
      label: `${totalArea.toFixed(2)} ha`,
      key: 'area',
    },
  ];

  const totals = [];
  const headersClassName = (index: number) =>
    `bg-primary-500 text-white border-t-0 sticky top-0 ${index === 1 ? 'w-[30%]' : ''}`;

  const CELL_CLASSNAMES = {
    columnHeader: headersClassName,
    row: (rowIndex: number) => 'bg-white',
  };
  const FOOTER_CELL_CLASSNAMES = {
    columnHeader: headersClassName,
  };
  const CUSTOM_CELL_RENDERER = {
    check: ({ item: field }) => (
      <BaseCell className="bg-white">
        <Checkbox
          id={field.uuid}
          value={field.uuid}
          onChange={() => toggleSelectField(field)}
          defaultChecked={isFieldSelected(field)}
        />
      </BaseCell>
    ),
  };

  return (
    <div className={classNames.tableContainer}>
      {!rows.length && (
        <div className={classNames.tableOverlay}>
          <ImportFieldviewDataButton
            onClick={async () => await importData()}
            loading={loadingFieldViewData}
          />
        </div>
      )}
      <div className="h-full overflow-x-clip overflow-y-auto">
        <Table
          data={rows as FieldData[]}
          idExtractor={(item) => String(item.id)}
          tableClassNames={CELL_CLASSNAMES}
          //@ts-ignore
          customCellRenderers={CUSTOM_CELL_RENDERER}
          //@ts-ignore
          columns={COLUMNS}
        />
      </div>
      <div className="h-[61px] overflow-clip">
        <Table
          data={totals as FieldData[]}
          idExtractor={(item) => item.fieldName}
          tableClassNames={FOOTER_CELL_CLASSNAMES}
          //@ts-ignore
          columns={COLUMNS_FOOTER}
        />
      </div>
    </div>
  );
};

export default GrowerDetailTable;
