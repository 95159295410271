export const classNames = {
  container: 'flex w-full',
  form: 'flex w-full p-4 gap-4 h-full justify-between',
  noteText: 'text-text-1 font-light',
  editIconContainer: 'flex items-center justify-end',
  sectionContainer: 'flex flex-col grow bg-background-1 border-gray-300 shadow-xl pb-4',
  sectionTitle:
    'flex items-center h-[53px] justify-between bg-primary-500 px-4 py-4 border-t border-primary-500 group',
  sectionTitleText: 'text-base text-sm text-white',
  flexWrap: 'flex flex-wrap',
  flexGap: 'flex gap-8 my-2 justify-between px-4',
  flexGapLarge: 'flex gap-12 my-2 mb-4 ',
  textSm: 'text-sm',
  textBaseBold: 'text-base font-bold',
  textBlue: 'text-sm text-[#0B617F] pl-2 mt-4 px-4 w-[230px]',
  buttonIcon: 'h-5 w-5 text-gray-500 hover:text-gray-700',
  fieldDivider: 'border-gray-300',
  fieldContainer: 'flex flex-col grow w-full overflow-auto',
  fieldHeaders: 'font-light',
  cardContent: 'flex flex-col items-center gap-4 mt-2 px-4 grow',
};
