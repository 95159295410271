import React from 'react';

const classNames = {
  container: 'layout flex h-full flex-grow',
  column1: 'h-full',
  column1Sizes: 'w-[200px] sm:w-[35%] lg:w-[25%]',
  column2: 'flex-grow h-full',
};

const ColumnsLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={classNames.container}>
      <div className={`${classNames.column1} ${classNames.column1Sizes}`}>{children[0]}</div>
      <div className={classNames.column2}>{children[1]}</div>
    </div>
  );
};

export default ColumnsLayout;
