import { useStateContext } from '../../../../utils/stateContext';
import { useTranslation } from 'react-i18next';
import Card from '../../../Layout/Card';
import EditButton from './EditButton';
import { useNavigate } from 'react-router-dom';

const GrowerDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    initialState: { sharedState },
  } = useStateContext();

  return (
    <Card
      title={`${t('GENERIC.FARM')} ${t('GENERIC.DETAILS')}`}
      icon={<EditButton onClick={() => navigate('/home/growerdetail')} />}
    >
      <div className="flex flex-col items-center gap-4 px-4">
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.START_DATE')}</span>
          <span>{sharedState.start_date}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.FARM')}</span>
          <span>{sharedState.name}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.CONTACT')}</span>
          <span>{sharedState.contact_name}</span>
        </div>
      </div>
    </Card>
  );
};

export default GrowerDetail;
