import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../CustomModal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import GrowerAPI from '../../api/grower';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from '@tcc/labz-design-system';
import { PDWindow, ProductItem } from '../../types/product';

const classNames = {
  modalTitle: 'text-lg font-semibold mb-4',
  button: 'bg-blue-500 text-white px-4 py-2 rounded',
  outlinedButton: 'border border-blue-500 text-blue-500 px-4 py-2 rounded',
  formControl: 'flex flex-col mb-4',
  input: 'border rounded p-2',
  error: 'text-red-500 text-sm',
  deleteIcon: 'cursor-pointer ml-2',
  addIcon: 'cursor-pointer',
  flexRow: 'flex flex-row items-center gap-4',
  flexColumn: 'flex flex-col mt-2 gap-2',
  flexEnd: 'flex justify-end',
  actionButton: 'w-36 h-9',
};

interface AddProductProps {
  openProduct: boolean;
  setOpenProduct: (boolean) => void;
  product: ProductItem;
  setProduct: (ProductItem) => void;
  tWindow: PDWindow;
  fetchGrowers: () => void;
}

interface Form {
  products?: { product?: string; dosage?: string }[];
}

const AddProduct = ({
  openProduct,
  setOpenProduct,
  product,
  setProduct,
  tWindow,
  fetchGrowers,
}: AddProductProps) => {
  const { t } = useTranslation();
  const [pd_window, setPDWindow] = useState<PDWindow | ''>(tWindow);

  const formattedProduct = {
    id: product?.[`${tWindow}id`],
    pd_window: tWindow,
    order_id: product.order_id,
    sent: product?.[`${tWindow}sent`],
  };

  const initialFormValues = [
    {
      product: product?.[`${tWindow}product`],
      dosage: product?.[`${tWindow}dosage`],
    },
  ];

  const defaultProducts = {
    T1: {},
    T2: { product: 'Kardix', dosage: '1.2' },
    T3: {},
  };

  const isEditing = !!formattedProduct.id;
  const modalTitle = isEditing ? t('ADD_PRODUCT.TITLE_UPDATE') : t('ADD_PRODUCT.TITLE_ADD');

  const handleTreatment = (value) => {
    setPDWindow(value);
  };

  const validationSchema = Yup.object().shape({
    products: Yup.array()
      .of(
        Yup.object().shape({
          product: Yup.string().required(t('ADD_PRODUCT.PRODUCT_REQUIRED')),
          dosage: Yup.string()
            .required(t('ADD_PRODUCT.DOSAGE_REQUIRED'))
            .matches(/^\d+(\.\d+)?(\s\/\s\d+(\.\d+)?)*$/, t('ADD_PRODUCT.DOSAGE_MUST_NUMBER')),
        })
      )
      .required(t('ADD_PRODUCT.ONE_PRODUCT_REQUIRED')),
  });

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      products: initialFormValues,
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  const handleAddProduct = () => {
    if (fields.length >= 5) return false;
    const newProduct = defaultProducts[pd_window] || { product: '', dosage: '' };
    append(newProduct);
  };

  const handleRemoveProduct = (index) => {
    if (fields.length === 1) return;
    remove(index);
  };

  const submit = ({ products }) => {
    let productValue = products.map((x) => x.product).join(' / ');
    let dosage = products.map((x) => x.dosage).join(' / ');
    let body = {
      order_id: product?.order_id,
      product: productValue,
      dosage: dosage,
      ...(!isEditing
        ? { id: formattedProduct?.id, pd_window: formattedProduct?.pd_window }
        : { pd_window: formattedProduct?.pd_window }),
    };

    setOpenProduct(false);
    GrowerAPI.addProduct(body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        toast.success(
          isEditing ? t('ADD_PRODUCT.UPDATED_SUCCESSFULLY') : t('ADD_PRODUCT.ADDED_SUCCESSFULLY')
        );
        fetchGrowers();
        handleClose();
      }
    });
  };

  const deleteProduct = (product) => {
    try {
      setOpenProduct(false);
      GrowerAPI.deleteProduct({ id: product?.id }).then((response) => {
        if (response?.status === 200) {
          toast.success(t('ADD_PRODUCT.DELETED_SUCCESSFULLY'));
          fetchGrowers();
          reset({ products: [] });
          setProduct({});
        } else {
          toast.error(t('ALERT.MSG2'));
        }
      });
    } catch (e) {
      toast.error(t('ALERT.MSG2'));
    }
  };

  const handleClose = () => {
    reset({
      products: [],
    });
    setOpenProduct(false);
    setProduct({});
    setPDWindow('');
  };

  useEffect(() => {
    setPDWindow(formattedProduct?.pd_window);
    if (isEditing) {
      setValue('products.0', {
        product: product?.[`${tWindow}product`],
        dosage: product?.[`${tWindow}dosage`],
      });
    }
    if (fields.length === 0 && openProduct) {
      const defaultProduct = defaultProducts[formattedProduct.pd_window];
      append(defaultProduct);
    }
  }, [product, openProduct]);

  return (
    <CustomModal title={modalTitle} open={openProduct} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submit)} className="p-4">
        <h2 className={classNames.modalTitle}>{t('ADD_PRODUCT.T_WINDOW')}</h2>
        <div className="flex gap-4">
          {['T1', 'T2', 'T3'].map((tWindow) => (
            <button
              key={tWindow}
              type="button"
              className={`${pd_window === tWindow ? classNames.button : classNames.outlinedButton}`}
              onClick={() => handleTreatment(tWindow)}
            >
              {t(`ADD_PRODUCT.${tWindow}`)}
            </button>
          ))}
        </div>
        <div className={classNames.flexColumn}>
          {fields.map((item, index) => (
            <div key={item.id} className={classNames.flexRow}>
              <div className={classNames.formControl}>
                <label htmlFor={`products[${index}].product`}>Product</label>
                <input
                  id={`products[${index}].product`}
                  {...register(`products.${index}.product`)}
                  className={`${classNames.input} ${errors.products?.[index]?.product ? classNames.error : ''}`}
                />
                {errors.products?.[index]?.product && (
                  <span className={classNames.error}>{errors.products[index].product.message}</span>
                )}
              </div>
              <div className={classNames.formControl}>
                <label htmlFor={`products[${index}].dosage`}>Dosage</label>
                <input
                  id={`products[${index}].dosage`}
                  {...register(`products.${index}.dosage`)}
                  className={`${classNames.input} ${errors.products?.[index]?.dosage ? classNames.error : ''}`}
                />
                {errors.products?.[index]?.dosage && (
                  <span className={classNames.error}>{errors.products[index].dosage.message}</span>
                )}
              </div>
              {index !== 0 && (
                <DeleteIcon
                  className={classNames.deleteIcon}
                  onClick={() => handleRemoveProduct(index)}
                  color="primary"
                />
              )}
              {index === 0 && fields.length === 1 && (
                <AddCircleIcon className={classNames.addIcon} onClick={handleAddProduct} />
              )}
            </div>
          ))}
          <div className={classNames.flexEnd}>
            {fields.length < 6 && fields.length > 1 && (
              <AddCircleIcon className={classNames.addIcon} onClick={handleAddProduct} />
            )}
          </div>
        </div>
        <div className="flex items-center justify-evenly mt-4">
          <button
            type="button"
            onClick={() => deleteProduct(product)}
            className={`${classNames.outlinedButton} ${classNames.actionButton}`}
            disabled={!isEditing}
          >
            {t('GENERIC.DELETE')}
          </button>
          <button type="submit" className={`${classNames.button} ${classNames.actionButton}`}>
            {t('GENERIC.SAVE')}
          </button>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddProduct;
