import Card from '../../../Layout/Card';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../utils/stateContext';
import HomeIcon from '../../../icons/HomeIcon';

const FarmDetails = () => {
  const { t } = useTranslation();
  const {
    initialState: { sharedState },
  } = useStateContext();

  return (
    <Card title={t('FARM.DETAILS')} icon={<HomeIcon className="w-5 h-5 text-white" />}>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.FARM')}</span>
        <span>{sharedState?.name}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.ADDRESS')}</span>
        <span>{sharedState?.address1}</span>
      </div>
    </Card>
  );
};

export default FarmDetails;
