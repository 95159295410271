import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, Typography, Icon, IconButton, Checkbox, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import BoxOpen from '../images/boxopen.png';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const dateFormat = 'DD-MM-YYYY';
export const ISODateFormat = 'YYYY-MM-DD';

export const formatDateTime = (date) => {
  const dateTimeFormat = 'DD-MM-YYYY | HH:mm';
  return dayjs(date).format(dateTimeFormat);
};

export const formatDate = (date, { inputFormat = dateFormat, outputFormat = dateFormat } = {}) => {
  return dayjs(date, inputFormat).format(outputFormat);
};

export const stringToBoolean = (stringBoolean) => {
  return stringBoolean.toLowerCase() === 'true';
};

export const formatNumber = (value, { digits = 2 } = {}) => {
  if (value) return Number(value).toFixed(digits);
  return null;
};
//Returns the value of a deeply key of an object
//Receives the data object and the route to the key separated by "."
const getNestedProperty = (obj, path) => {
  return path ? path?.split('.').reduce((acc, part) => acc && acc[part], obj) : null;
};

export const getCellValue = (dataRow, column) => {
  if (column?.valueGetter && typeof column.valueGetter === 'function') {
    return column.valueGetter(dataRow);
  }
  return getNestedProperty(dataRow, column?.name);
};

export const StyledBox = ({ children }) => {
  return <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>{children}</Box>;
};
export const FlexBox = ({ children, text1, text2 }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '0.5vw',
        padding: '0.1vw 0.5vw',
      }}
    >
      <Icon color="primary">{children}</Icon>
      <Typography color="primary" sx={{ fontSize: '12px' }}>
        {text1} <br />
        {text2}
      </Typography>
    </Box>
  );
};

export const FlexBox1 = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5vw',
        padding: '0.5vw',
      }}
    >
      {children}
    </Box>
  );
};
export const EditIcon = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <IconButton color="primary" onClick={onClick}>
      <span style={{ fontSize: '12px' }}>{t('GENERIC.EDIT')}</span>
      <EditOutlinedIcon sx={{ width: '0.5em', height: '1em' }} />
    </IconButton>
  );
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    align: 'center',
    color: 'white',
    border: '1px solid #DDDDDD',
  },
  [`&.${tableCellClasses.root}`]: {
    fontSize: 13,
    border: '1px solid #DDDDDD',
    padding: '0.5vw 1vw',
  },
}));

export const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    color: 'white',
    padding: '0.1vw',
    border: '1px solid #DDDDDD',
  },
  [`&.${tableCellClasses.root}`]: {
    fontSize: 13,
    border: '1px solid #DDDDDD',
    padding: '0.1vw',
  },
}));

export const NoDataAvailable = ({ text1, text2 }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2vw 0vw 7vw 0vw',
      }}
    >
      <Typography variant="body2" sx={{ fontSize: '14px' }}>
        {text1}
      </Typography>
      <img src={BoxOpen} alt="nodata" />
      <Typography variant="body2" sx={{ fontWeight: 550, mt: 1 }}>
        {text2}
      </Typography>
    </Box>
  );
};

export const DateComponent = ({ name, label, control, errors, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...props}
            {...field}
            views={['month', 'day']}
            label={label}
            minDate={dayjs('01-01-2023')}
            format="DD-MM-YYYY"
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                error: errors.start_date ? false : true,
                helperText: errors.start_date?.message,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export const DateComponentFieldView = ({ name, label, control, errors, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...props}
            {...field}
            label={label}
            format="DD-MM-YYYY"
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                error: errors.sowing_date ? false : true,
                helperText: errors.sowing_date?.message,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export const uniqueNames = (data) => {
  const uniqueObjects = [];
  const uniqueNames = new Set();
  for (const obj of data) {
    const name = obj.name;
    if (!uniqueNames.has(name)) {
      uniqueNames.add(name);
      uniqueObjects.push(obj);
    }
  }
  return uniqueObjects;
};

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
export function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

export const sortData = (data, field) => {
  return data.sort((a, b) => {
    const nameA = a[field]?.toUpperCase(); // ignore upper and lowercase
    const nameB = b[field]?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
};

export const HtmlTooltip = (props: TooltipProps) => {
  const theme = useTheme();
  return (
    <Tooltip
      {...props}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#FFF',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 'auto',
          fontSize: theme.typography.pxToRem(12),
          p: 0,
        },
      }}
    />
  );
};
