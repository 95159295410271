import { Typography, Select, MenuItem } from '@mui/material';
import React from 'react';
import Dot from '../Layout/Dot';
import './agronomy.css';

const RiskFilter = ({ onChange, value = 'All' }) => {
  return (
    <Select sx={{ display: 'flex', flex: 1, height: 30 }} value={value} onChange={onChange}>
      <MenuItem value="All">
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>All</Typography>
      </MenuItem>
      <MenuItem value={30}>
        <Dot color={30} size="0.5em" />
      </MenuItem>
      <MenuItem value={20}>
        <Dot color={20} size="0.5em" />
      </MenuItem>
      <MenuItem value={10}>
        <Dot color={10} size="0.5em" />
      </MenuItem>
      <MenuItem value={40}>
        <Dot color={40} size="0.5em" />
      </MenuItem>
      <MenuItem value={0}>
        <Dot color={0} size="0.5em" />
      </MenuItem>
    </Select>
  );
};

export default RiskFilter;
