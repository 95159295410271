import { DefaultValues } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useFormFactory from '../useFormFactory';
import { CountryCode } from '../../api/user/types';

type AddGrowerFormValues = {
  start_date: string;
  name: string;
  contact_name: string;
  mobile_no: string;
  email: string;
  telephone_no?: string;
  address1: string;
  city1: string;
  postal_code1: string;
  france_details: {
    no_certiphyto: string;
    date_echeance: string;
  };
};

const useGrowerForm = (
  onSubmit,
  editable,
  editFromReview,
  sharedState,
  userDetails,
  translationsKey
) => {
  const { t } = useTranslation('', { keyPrefix: `${translationsKey}.FIELDS` });
  const phoneNumberRules = /^(?:(?:\+|00)33|0)[67]\d{8}$/;
  const distributorCountryCode = userDetails.distributor.country.code;

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().typeError(t('DATE_INVALID.REQUIRED')).required(t('DATE.REQUIRED')),
    name: Yup.string().required(t('FARM_NAME.REQUIRED')),
    contact_name: Yup.string().required(t('NAME.REQUIRED')),
    mobile_no: Yup.string()
      .matches(phoneNumberRules, { message: t('MOBILE_NO.INVALID') })
      .required(t('MOBILE_NO.REQUIRED')),
    email: Yup.string().required(t('EMAIL.REQUIRED')).email(t('EMAIL.INVALID')),
    telephone_no: Yup.string().matches(/^(\d{10})?$/, {
      message: t('TELEPHONE.INVALID'),
      excludeEmptyString: true,
    }),
    address1: Yup.string(),
    city1: Yup.string().required(t('CITY.REQUIRED')),
    postal_code1: Yup.string()
      .required(t('POSTAL_CODE.REQUIRED'))
      .matches(/^(\d{5})?$/, {
        message: t('POSTAL_CODE.INVALID'),
        excludeEmptyString: true,
      }),
    france_details: Yup.lazy(() => {
      if (distributorCountryCode === CountryCode.FR) {
        return Yup.object().shape({
          no_certiphyto: Yup.string().required(t('NO_CERTIPHYTO.REQUIRED')),
          date_echeance: Yup.date().required(t('DATE_ECHEANCE.REQUIRED')),
        });
      }
      return Yup.mixed().notRequired();
    }),
  });

  const defaultValues: DefaultValues<AddGrowerFormValues> = {
    start_date: '',
    name: '',
    contact_name: '',
    mobile_no: '',
    email: '',
    telephone_no: '',
    address1: '',
    city1: '',
    postal_code1: '',
    france_details: {
      no_certiphyto: '',
      date_echeance: '',
    },
  };

  // If editable or editing from review, set default values from sharedState
  if (editable || editFromReview) {
    defaultValues.start_date = sharedState.start_date || '';
    defaultValues.name = sharedState.name || '';
    defaultValues.contact_name = sharedState.contact_name || '';
    defaultValues.mobile_no = sharedState.mobile_no || '';
    defaultValues.email = sharedState.email || '';
    defaultValues.telephone_no = sharedState.telephone_no || '';
    defaultValues.address1 = sharedState.address1 || '';
    defaultValues.city1 = sharedState.city1 || '';
    defaultValues.postal_code1 = sharedState.postal_code1 || '';
    if (distributorCountryCode === CountryCode.FR) {
      defaultValues.france_details.no_certiphyto = sharedState.no_certiphyto;
      defaultValues.france_details.date_echeance = sharedState.date_echeance;
    }
  }

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
    translationsKey,
  };

  return {
    ...useFormFactory(formFactoryProps),
  };
};

export default useGrowerForm;
